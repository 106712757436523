/* eslint-disable react-hooks/exhaustive-deps */
import { EXPORT_ORDER_TYPE, ITEM_DATA_TYPE } from "features/Order/constants";
import { useCloneCart } from "hooks/cart";
import { useKeyManagementPermissions } from "hooks/keyManagement/query";
import {
  useApproveOrder,
  useApproveStock,
  useCancelOrder,
  useCloneCartUpdateDetail,
  useCompleteAssembly,
  useConfirmD3P,
  useConfirmDeliver,
  useDenyStock,
  useExportOrder,
  useGetOrderDetail,
  useOrderPermissions,
  usePackageOrder,
  useWaitingStock,
} from "hooks/order";
import { t } from "i18next";
import { pick } from "lodash";
import printJS from "print-js";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { notify } from "utils/helperFuncs";

const useDetail = () => {
  const { id } = useParams();
  const { handleExportOrder, loading: loadingExport } = useExportOrder();

  const { canPromotionOrderView } = useOrderPermissions();
  const [params, setParams] = useState({ id: id, itemDataType: ITEM_DATA_TYPE.ORDER_ITEM });

  useEffect(() => {
    if (canPromotionOrderView) {
      setParams({ id: id, itemDataType: ITEM_DATA_TYPE.COMBO_ORDER_ITEM });
    }
    setParams({ id: id, itemDataType: ITEM_DATA_TYPE.ORDER_ITEM });
  }, [JSON.stringify(canPromotionOrderView)]);

  const { order, loading: loadingOrder } = useGetOrderDetail({ params });
  const { handleApproveOrder, loading: loadingApproveOrder } = useApproveOrder();
  const { handleCancelOrder, loading: loadingCancelOrder } = useCancelOrder();
  const { handleWaitingStock, loading: loadingWaitingStock } = useWaitingStock();
  const { handleDenyStock, loading: loadingDenyStock } = useDenyStock();
  const { handleApproveStock, loading: loadingApproveStock } = useApproveStock();
  const { handlePackageOrder, loading: loadingPackageOrder } = usePackageOrder();
  const { handleCompleteAssembly, loading: loadingCompleteAssembly } = useCompleteAssembly();
  const { handleConfirmD3P, loading: loadingConfirmD3P } = useConfirmD3P();
  const { handleConfirmDeliver, loading: loadingConfirmShipper } = useConfirmDeliver();
  const { handleCloneCart, loading: loadingCloneCart } = useCloneCart();
  const {
    canApproveStock,
    canAssignShipper,
    canCancelOrder,
    canCompleteAssembly,
    canCompleteOrder,
    canConfirmPayment,
    canDenyStock,
    canExportStock,
    canRecreateCart,
    canViewOrder,
    canWaitStock,
    canApproveOrder,
    canOrderCancelBeforePayView,
    canOrderCancelAfterPayView,
    loading: loadingPermission,
  } = useOrderPermissions();

  const { canSuggestKeyLicenseAccountConfirm, canSuggestKeyLicenseAccountCancel } = useKeyManagementPermissions();
  const { handleCloneCartUpdateDetail, loading: loadingCloneCartUpdateDetail } = useCloneCartUpdateDetail();

  const history = useHistory();

  const onExportOrder = async (type) => {
    await handleExportOrder({ orderID: id, fileType: type })
      .then((response) => {
        const fileUrl = response.data.order.exportFile.url;
        window.open(fileUrl, "_blank").focus();
      })
      .catch(() => {
        notify.error({ message: t("common.error") });
      });
  };

  const onPrintOrder = async () => {
    await handleExportOrder({
      orderID: id,
      fileType: EXPORT_ORDER_TYPE.PDF,
    })
      .then((response) => {
        const fileUrl = response.data.order.exportFile.url;
        printJS({
          printable: fileUrl,
          type: EXPORT_ORDER_TYPE.PDF.toLowerCase(),
          showModal: true,
          modalMessage: t("common.loadingData"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderDetail.exportOrderError"),
          description: error.message,
        });
      });
  };

  const onCancelOrder = async (reason) => {
    if (reason) {
      await handleCancelOrder({ orderIDs: [id], reason })
        .then(() => {
          notify.success({
            message: t("order.cancelOrder.cancelOrderSuccess"),
          });
        })
        .catch((error) => {
          notify.error({
            message: t("order.cancelOrder.cancelOrderError"),
            description: error.message,
          });
        });
    } else {
      notify.warning({
        message: t("order.cancelOrder.warningMessage2"),
      });
    }
  };

  const onEditOrder = async () => {
    await handleCloneCartUpdateDetail({ orderID: id }).then((res) => {
      history.push({
        pathname: `/order-edit/${res?.data?.order?.cloneCartUpdateDetail?.id}`,
        search: `?orderID=${id}`,
      });
    });
  };

  const onWaitingForEnoughStock = async () => {
    await handleWaitingStock({ orderIDs: [id] })
      .then(() => {
        notify.success({
          message: t("order.orderList.waitingForStockSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.waitingForStockError"),
          description: error.message,
        });
      });
  };

  const onDeny = async () => {
    await handleDenyStock({ orderIDs: [id] })
      .then(() => {
        notify.success({
          message: t("order.orderList.denyStockSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.denyStockError"),
          description: error.message,
        });
      });
  };

  const onApproveStock = async () => {
    await handleApproveStock({ orderIDs: [id] })
      .then(() => {
        notify.success({
          message: t("order.orderList.confirmStockSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.confirmStockError"),
          description: error.message,
        });
      });
  };

  const onExportOrderWithPrint = async (handlePrintDialogClose) => {
    await handleExportOrder({ orderID: id, fileType: EXPORT_ORDER_TYPE.PDF })
      .then((response) => {
        const fileUrl = response.data.order.exportFile.url;
        printJS({
          printable: fileUrl,
          type: EXPORT_ORDER_TYPE.PDF.toLowerCase(),
          showModal: true,
          modalMessage: t("common.loadingData"),
          onPrintDialogClose: handlePrintDialogClose,
        });
      })
      .catch(() => {
        notify.error({ message: t("common.error") });
      });
  };

  const onPackage = async () => {
    await handlePackageOrder({ orderIDs: [id] })
      .then(() => {
        notify.success({
          message: t("order.orderList.packageSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.packageError"),
        });
      });
    // await handleCancelOrder();
  };

  const onExportConfirm = async () => {
    const url = "/export-serial/create";
    const data = {
      orderInfoState: order,
    };
    const newTab = window.open();
    newTab.location.href = url;
    newTab.orderInfoState = data;
  };

  const onAssemblyConfirm = async () => {
    await handleCompleteAssembly({ orderIDs: [id] })
      .then(() => {
        notify.success({
          message: t("order.orderList.confirmAssembleSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.confirmAssembleError"),
          description: error.message,
        });
      });
  };

  const onConfirmShipper = async (shipperID) => {
    await handleConfirmDeliver({
      orderIDs: [id],
      shipperID,
    })
      .then(() => {
        notify.success({
          message: t("order.selectingShipper.confirmDeliverSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.selectingShipper.confirmDeliverError"),
          description: error.message,
        });
      });
  };

  const onConfirmD3P = async () => {
    await handleConfirmD3P({
      orderIDs: [id],
    })
      .then(() => {
        notify.success({
          message: t("order.orderList.confirm3PDeliverySuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderList.confirm3PDeliveryError"),
          description: error.message,
        });
      });
  };

  const onRecreateOrder = async () => {
    await handleCloneCart(order?.cartID)
      .then((response) => {
        const id = response?.data?.cart?.clone?.id;
        history.push({
          pathname: "/cart",
          search: `?id=${id}`,
        });
        notify.success({
          message: t("order.orderDetail.recreateCartSuccess"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.orderDetail.recreateCartError"),
          description: error?.message,
        });
      });
  };

  const onApproveOrder = async ({ warehouseID }) =>
    await handleApproveOrder({ orderIDs: [id], updateWarehouseID: warehouseID })
      .then(() => {
        notify.success({
          message: t("order.approvalOrder.success"),
        });
      })
      .catch((error) => {
        notify.error({
          message: t("order.approvalOrder.error"),
          description: error.message,
        });
      });

  const dataToolBar = pick(order, ["shareLink"]);

  const dataOrderCodeAndOrderStatus = pick(order, [
    "preOrder",
    "number",
    "status",
    "seller",
    "fullEvents",
    "shipperName",
    "shipperTelephone",
    "shippingType",
    "shippingStatus",
    "orderParcel",
    "saleChannel",
    "accountingNumber",
    "saleChannel",
    "orderType",
    "orderReconciliationStatus",
  ]);

  const dataInfoAgent = pick(order, [
    "seller",
    "orderType",
    "senderName",
    "senderPhone",
    "senderAddress",
    "receiverContactName",
    "receiverContactPhone",
    "receiverContactAddress",
    "shippingConfiguration",
    "shippingType",
    "orderPriority",
    "exportedWarehouse",
    "deliveryDeadline",
    "chatURL",
    "fullStockRequired",
    "vat",
    "vatAllOrder",
    "vatTaxIDNumber",
    "vatCompanyName",
    "vatEmail",
    "vatAddress",
    "shippingFee",
    "shippingVersion",
    "customerVatEmail",
    "vatCustomerName",
    "customerVatIDNumber",
    "customerVatAddress",
    "orderReconciliationStatus",
  ]);
  const dataInfoProductAndBill = pick(order, [
    "items",
    "status",
    "exportedWarehouseID",
    "orderType",
    "paymentMethod",
    "seller",
    "shippingFee",
    "totalServiceProduct",
    "discount",
    "subTotal",
    "customerSubTotal",
    "customerTotal",
    "total",
    "note",
    "internalNote",
    "customerPaymentMethod",
    "totalPromotionDiscountMoney",
    "shippingType",
    "shippingConfiguration",
    "shippingStatus",
    "paymentStatus",
    "paymentInfo",
    "debtPayment",
    "exportedWarehouse",
    "customerShippingFee",
    "customerTotalServiceProduct",
    "sellerProfitAmount",
  ]);

  const dataButtonGroup = {
    ...pick(order, [
      "preOrder",
      "status",
      "stockStatus",
      "shippingConfiguration",
      "shippingType",
      "items",
      "id",
      "exportedWarehouse",
      "number",
      "serviceFee",
      "discount",
      "shippingFee",
      "paymentMethod",
      "orderType",
      "shippingStatus",
      "shippingVersion",
      "deliveryDeadline",
      "chatURL",
      "seller",
    ]),
    canApproveStock,
    canAssignShipper,
    canCancelOrder,
    canCompleteAssembly,
    canCompleteOrder,
    canConfirmPayment,
    canDenyStock,
    canExportStock,
    canRecreateCart,
    canWaitStock,
    canApproveOrder,
    canOrderCancelBeforePayView,
    canOrderCancelAfterPayView,
    loadingApproveOrder,
    canSuggestKeyLicenseAccountConfirm,
    canSuggestKeyLicenseAccountCancel,
  };

  const dataImageLibrary = pick(order, ["fileURLs"]);

  const dataCancellationReason = pick(order, ["cancelReason", "fullEvents"]);

  return {
    onExportOrder,
    onPrintOrder,
    dataToolBar,
    canViewOrder,
    loading:
      loadingExport ||
      loadingOrder ||
      loadingCancelOrder ||
      loadingWaitingStock ||
      loadingDenyStock ||
      loadingApproveStock ||
      loadingPackageOrder ||
      loadingCompleteAssembly ||
      loadingConfirmD3P ||
      loadingConfirmShipper ||
      loadingCloneCart ||
      loadingPermission ||
      loadingCloneCartUpdateDetail ||
      loadingApproveOrder,
    dataOrderCodeAndOrderStatus,
    dataInfoAgent,
    dataInfoProductAndBill,
    dataButtonGroup,
    dataImageLibrary,
    dataCancellationReason,
    loadingPermission,
    order,
    actions: {
      onCancelOrder,
      onWaitingForEnoughStock,
      onDeny,
      onApproveStock,
      onExportOrderWithPrint,
      onPackage,
      onExportConfirm,
      onAssemblyConfirm,
      onConfirmD3P,
      onConfirmShipper,
      onRecreateOrder,
      onEditOrder,
      onApproveOrder,
    },
  };
};

export default useDetail;
